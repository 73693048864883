import { useAppContext } from "context";
import apiService from "./api";
const personnelurl = process.env.REACT_APP_SHARED_BASE_URL as string;

const invitePersonnelService = async (body: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const url = `/personnel`;
  const method = "post";
  return apiService(url, method, body, true, headers, {}, personnelurl);
};

const assignRoleToUserPersonnelService = async (body: any) => {
  const url = `/personnel/assign-role-to-user`;
  const method = "post";
  return apiService(url, method, body, true, {}, {}, personnelurl);
};

const getAllPersonnelService = async (params: {
  page: number;
  size: number;
  firstName?: string;
  department?: string;
  position?: string;
  role?: string[];
}) => {
  const { page, size, firstName, department, position, role } = params;
  let url = `/personnel?page=${page}&size=${size}`;
  if (firstName) {
    url += `&firstName=${firstName}`;
  }
  if (department) {
    url += `&department=${department}`;
  }
  if (position) {
    url += `&position=${position}`;
  }
  if (role && role.length > 0) {
    url += role.map((r) => `&roles=${r}`).join("");
  }
  const method = "get";
  return apiService(url, method, null, true, {}, {}, personnelurl);
};

const getPersonnelByIdService = async (publicId: any) => {
  const url = `/personnel/${publicId}`;
  const method = "get";
  return apiService(url, method, null, true, {}, {}, personnelurl);
};

const editPersonnelService = async (publicId: any, body: any) => {
  const url = `/personnel/${publicId}`;
  const method = "put";
  return apiService(url, method, body, true, {}, {}, personnelurl);
};

const deletePersonnelService = async (publicId: any) => {
  const url = `/personnel/${publicId}`;
  const method = "delete";
  return apiService(url, method, null, true, {}, {}, personnelurl);
};

const updateProfilePics = async (pictureUrl: string) => {
  const url = "/personnel/update-user-profile-pics";
  const method = "post";
  const data = {
    pictureUrl: pictureUrl,
  };
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService(url, method, data, true, headers, {}, personnelurl);
};

const updatePersonnel = async (data: any, Id: any) => {
  const url = `/personnel/${Id}`;
  const method = "put";
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService(url, method, data, true, headers, {}, personnelurl);
};

const disable2FA = async () => {
  const url = "/personnel/disable-2fa";
  const method = "post";
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService(url, method, {}, true, headers, {}, personnelurl);
};
const setup2FA = async () => {
  const url = "/personnel/setup-2fa";
  const method = "post";
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService(url, method, {}, true, headers, {}, personnelurl);
};

const enableMultifactorauthentication = async () => {
  return apiService(
    "/personnel/setup-2fa",
    "post",
    undefined,
    true,
    {},
    undefined,
    personnelurl
  );
};

const disableMultifactorauthentication = async () => {
  return apiService(
    "/personnel/disable-2fa",
    "post",
    undefined,
    true,
    {},
    undefined,
    personnelurl
  );
};

const getRoles = async () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService(
    "/roles",
    "get",
    undefined,
    true,
    headers,
    undefined,
    personnelurl
  );
};
const getSettingsNotification = async (id: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService(
    `/settings/notification/${id}`,
    "get",
    undefined,
    true,
    headers,
    undefined,
    personnelurl
  );
};
const getPersonel = async (id: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService(
    `/personnel/${id}`,
    "get",
    undefined,
    true,
    headers,
    undefined,
    personnelurl
  );
};
const updatePersonel = async (id: any, body: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService(
    `/personnel/${id}`,
    "put",
    body,
    true,
    headers,
    undefined,
    personnelurl
  );
};
const updateSettingsNotification = async (id: any, body: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService(
    `/settings/notification/${id}`,
    "put",
    body,
    true,
    headers,
    undefined,
    personnelurl
  );
};

export {
  invitePersonnelService,
  updateSettingsNotification,
  enableMultifactorauthentication,
  getPersonel,
  getSettingsNotification,
  disableMultifactorauthentication,
  editPersonnelService,
  updatePersonel,
  getAllPersonnelService,
  getPersonnelByIdService,
  setup2FA,
  disable2FA,
  deletePersonnelService,
  updateProfilePics,
  updatePersonnel,
  getRoles,
  assignRoleToUserPersonnelService,
};
