import React, { useEffect, useState } from "react";
import CustomIcon from "components/custom-icon";
import { ReactComponent as HelpCircle } from "assets/icons/help-circle-contained.svg";
import { ReactComponent as left } from "assets/icons/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import { Checkbox, CheckboxProps, Spin } from "antd";
import QuillToolbar, { formats, modules } from "helpers/quill/QuillToolBar";
import SelectComponent from "helpers/select-input";
import { ReactComponent as img1 } from "assets/icons/Iso1.svg";
import { ReactComponent as img2 } from "assets/icons/soc1.svg";
import { ReactComponent as img3 } from "assets/icons/iso2.svg";
import { ReactComponent as img4 } from "assets/icons/iso3.svg";
import { ReactComponent as img5 } from "assets/icons/PCI.svg";
import { ReactComponent as img6 } from "assets/icons/ndpb.svg";
import { ReactComponent as edit } from "assets/icons/edit-03.svg";
// import { ReactComponent as HelpCircle } from "assets/icons/help-circle-contained.svg";
import ReactQuill from "react-quill";
import mammoth from "mammoth";
import * as XLSX from "xlsx";
import { GlobalWorkerOptions, getDocument as getDocs } from "pdfjs-dist";
import { Policy } from "types";
import { useAppContext, useFrameworkContext, useUploadContext } from "context";
import useFetch from "hooks/useFetch";
import {
  getAllPersonnelService,
  getFrameworksByIds,
  uploadFilesService,
} from "services";
import { createEvidenceService } from "services/evidenceHub";
import { Document, Packer, Paragraph, TextRun, HeadingLevel } from "docx";
import { ContentState, convertFromHTML, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import Loader from "components/loader";
import { LoadingOutlined } from "@ant-design/icons";
import { toast } from "sonner";
import { getCustomisedTemplateByName } from "services/customized";
import { IPersonnel } from "types/personnel";
import { getFileExtension } from "utils/general";

interface IDocumentItem {
  publicId: string;
  createdDate: string;
  lastModifiedDate: string;
  createdBy: string | null;
  lastModifiedBy: string | null;
  documentUrl: string;
  documentName: string;
  vegeelFrameworkName: string;
  vegeelFrameworkPublicId: string | null;
}

interface IDocumentItemResponse {
  timestamp: string;
  data: {
    content: IDocumentItem[];
    page: {
      size: number;
      number: number;
      totalElements: number;
      totalPages: number;
    };
  };
  isSuccess: boolean;
  isError: boolean;
  description: string;
  status: string;
}

GlobalWorkerOptions.workerSrc =
  "https://unpkg.com/pdfjs-dist/build/pdf.worker.min.js";

interface OptionType {
  value: string;
  label: string;
}

interface FetchResponse {
  data: {
    content: Policy[];
    page: {
      size: number;
      number: number;
      totalElements: number;
      totalPages: number;
    };
  };
}

const options: OptionType[] = [
  { value: "PUBLIC", label: "Public" },
  { value: "INTERNAL_USE", label: "Internal use" },
  { value: "CONFIDENTIAL", label: "Confidential" },
  { value: "PRIVATE", label: "Private" },
];

const review: OptionType[] = [
  { value: "YEARLY", label: "Yearly" },
  { value: "QUARTERLY", label: "Quarterly" },
  { value: "MONTHLY", label: "Monthly" },
];

interface PersonnelOptionType {
  value: string;
  label: string;
  personnel: IPersonnel;
}

interface Framework {
  publicId: string;
  name: string;
}

export default function CustomisedEditor({ name }: any) {
  const [data, setData] = useState<any | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [tempDocumentName, setTempDocumentName] = useState("");
  const [value, setValue] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [documentInfo, setDocumentInfo] = useState<IDocumentItem | null>(null);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [personnel, setPersonnel] = useState<PersonnelOptionType[]>([]);

  const {
    documentName,
    classification,
    reviewCycle,
    owner,
    version,
    approver,
    vegeelFrameworkPublicIds,
    setDocumentName,
    setClassification,
    setReviewCycle,
    setOwner,
    setVersion,
    setApprover,
    setVegeelFrameworkPublicIds,
  } = useUploadContext();

  const { personnelInfoState } = useAppContext();
  const { activeFrameworks } = useFrameworkContext();

  const { getOrganizationInfo } = useAppContext();
  const orgInfo = getOrganizationInfo();

  const navigate = useNavigate();

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const onChange: CheckboxProps["onChange"] = (e) => {
    const { value, checked } = e.target;
    setVegeelFrameworkPublicIds((prev: any) =>
      checked ? [...prev, value] : prev.filter((item: any) => item !== value)
    );
  };

  const {
    data: uploadDocsData,
    error: uploadDocsError,
    loading: uploadDocsLoading,
    fetchData: uploadDocsFetchData,
  } = useFetch<any>();

  const {
    data: documentData,
    error,
    fetchData,
    loading,
  } = useFetch<IDocumentItemResponse>();

  // const {
  //   data: getFrameworkData,
  //   error: getFrameworkError,
  //   loading: getFrameworkLoading,
  //   fetchData: getFrameworkFetchData,
  // } = useFetch<any>();

  const {
    data: dataSubmit,
    error: errorSubmit,
    loading: loadingSubmit,
    fetchData: fetchSubmit,
  } = useFetch();

  // useEffect(() => {
  //   if (activeFrameworks) {
  //     getFrameworkFetchData(() =>
  //       getFrameworksByIds(
  //         activeFrameworks.map((framework) => framework.publicId)
  //       )
  //     );
  //   }
  // }, []);

  useEffect(() => {
    if (dataSubmit) {
      navigate(`/evidence-hub`);
    }
  }, [dataSubmit, errorSubmit, navigate]);

  useEffect(() => {
    if (personnelInfoState) {
      const personnelDropdown = personnelInfoState.content.map((personnel) => {
        return {
          value: personnel.publicId,
          label: personnel.firstName + " " + personnel.lastName,
          personnel,
        };
      });
      setPersonnel(personnelDropdown);
    }
  }, [personnelInfoState]);

  useEffect(() => {
    if (name) {
      fetchData(() => getCustomisedTemplateByName(name));
    }
  }, [name]);

  useEffect(() => {
    if (documentData) {
      setDocumentInfo(documentData.data.content[0]);
      setTempDocumentName(documentData.data.content[0].documentName);
    }
  }, [documentData]);

  useEffect(() => {
    if (uploadDocsData && uploadDocsData.data.url) {
      const payload = {
        documentName: tempDocumentName,
        documentUrl: uploadDocsData.data.url,
        classification: classification.value,
        version: version,
        reviewCycle: reviewCycle.value,
        ownerPublicId: owner,
        approverPublicId: approver,
        vegeelFrameworkPublicIds: vegeelFrameworkPublicIds,
      };

      fetchSubmit(() => createEvidenceService(payload));
    }
  }, [
    approver,
    classification,
    fetchSubmit,
    owner,
    reviewCycle,
    tempDocumentName,
    uploadDocsData,
    vegeelFrameworkPublicIds,
    version,
  ]);

  useEffect(() => {
    if (documentInfo) {
      const getDocument = async () => {
        try {
          const docName = documentInfo.documentName;
          const response = await fetch(
            documentInfo.documentUrl.replace("http://", "https://")
          );
          const arrayBuffer = await response.arrayBuffer();
          const fileExtension = getFileExtension(docName);

          switch (fileExtension) {
            case "docx":
              const result = await mammoth.convertToHtml({
                arrayBuffer: arrayBuffer,
              });
              const html = result.value;

              setValue(html);
              break;

            case "pdf":
              const pdf = await getDocs({ data: arrayBuffer }).promise;
              const page = await pdf.getPage(1);
              const textContent = await page.getTextContent();
              const pdfText = textContent.items
                .map((item: any) => item.str)
                .join(" ");
              setValue(pdfText);
              break;

            case "xlsx":
              const workbook = XLSX.read(arrayBuffer, { type: "array" });
              const sheetNames = workbook.SheetNames;
              const sheet = workbook.Sheets[sheetNames[0]];
              const excelData = XLSX.utils.sheet_to_html(sheet);
              setValue(excelData);
              break;

            default:
              console.error("Unsupported file type");
              break;
          }
        } catch (error) {
          console.error("Error processing document:", error);
        }
      };

      getDocument();
    }
  }, [documentData, documentInfo]);

  const handleChange = (value: string) => {
    setValue(value);
  };

  const onEditClick = () => {
    setIsEditable(true);
  };

  const handleInputBlur = () => {
    if (data) {
      setData({ ...data, documentName: tempDocumentName });
    }
    setIsEditing(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempDocumentName(e.target.value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleInputBlur();
    }
  };

  const convertDraftToDocx = (content: any) => {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: content.blocks.map((block: any) => {
            switch (block.type) {
              case "header-one":
                return new Paragraph({
                  heading: HeadingLevel.HEADING_1,
                  children: [new TextRun(block.text)],
                });
              case "header-two":
                return new Paragraph({
                  heading: HeadingLevel.HEADING_2,
                  children: [new TextRun(block.text)],
                });
              case "paragraph":
              default:
                return new Paragraph({
                  children: [new TextRun(block.text)],
                });
            }
          }),
        },
      ],
    });
    return doc;
  };

  const handleUploadDocument = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();

    if (value && documentData) {
      const contentBlock = htmlToDraft(value);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
        contentBlock.entityMap
      );
      const rawContent = convertToRaw(contentState);
      const doc = convertDraftToDocx(rawContent);

      const blob = await Packer.toBlob(doc);
      const file = new File([blob], documentData.data.content[0].documentName, {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      console.log("file", file);
      console.log(
        "tempDocumentName",
        documentData.data.content[0].documentName
      );

      uploadDocsFetchData(() =>
        uploadFilesService(file, documentData.data.content[0].documentName)
      );
    }
  };

  useEffect(() => {
    if (error || errorSubmit) {
      toast.error(error || errorSubmit);
    }
  }, [errorSubmit, error]);

  if (loading || loadingSubmit) {
    return <Loader />;
  }

  return (
    <div className="h-full ">
      <div className="h-[36px] flex justify-between">
        <div className="bg-[#F2F5FF] px-[12px] py-[6px] rounded-md flex justify-center gap-[10px] items-center">
          <span className="text-[#1B4DFF] text-sm leading-[24px] font-medium">
            Create document
          </span>
          <CustomIcon SvgIcon={HelpCircle} size={18} />
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => navigate(-1)}
            className="font-medium text-sm text-[#455468] border border-[#D7DFE9] bg-white flex gap-1 py-2 px-4 rounded-md"
          >
            <CustomIcon SvgIcon={left} size={18} />
            Back
          </button>
        </div>
      </div>
      <div className="w-full h-[calc(100%-34px)] ">
        <div className="w-full h-fi">
          <div className="bg-white rounded-md w-fit py-2 px-4 text-lg font-bold text-[#2D3643] my-4 border border-[#EAECF0] flex gap-[50px]">
            {isEditing ? (
              <input
                type="text"
                value={tempDocumentName || name}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onKeyDown={handleInputKeyDown}
                className="outline-none border-b-2 border-blue-500"
                autoFocus
              />
            ) : (
              <h1>{tempDocumentName || name}</h1>
            )}
            <span onClick={handleEditClick} className="cursor-pointer">
              <CustomIcon SvgIcon={edit} size={24} />
            </span>
          </div>
        </div>

        <div className="w-full mt-2">
          <form className="w-full">
            <div className="w-full flex justify-between">
              <div className="w-[62%] h-full">
                <QuillToolbar toolbarId={"t1"} />
                <ReactQuill
                  className="border border-[#D7DFE9] bg-white !h-[600px]"
                  theme="snow"
                  value={value}
                  readOnly={false}
                  onChange={handleChange}
                  modules={modules("t1")}
                  formats={formats}
                />
              </div>

              <div className="flex flex-col gap-y-5 w-[35%]">
                <div className="bg-[#F2F5FF] px-[12px] py-[6px] rounded-md flex justify-center gap-[10px] items-center w-fit">
                  <span className="text-[#1B4DFF] text-sm leading-[24px] font-medium">
                    Document Properties
                  </span>
                  <CustomIcon SvgIcon={HelpCircle} size={18} />
                </div>

                <div>
                  <label htmlFor="" className="font-medium text-sm">
                    Classification<sup className="text-[#1B4DFF]">*</sup>
                  </label>
                  <div className="mt-1">
                    <SelectComponent
                      placeholder="eg. Public, Internal use, Confidential, Private"
                      options={options}
                      value={classification}
                      onChange={(value) => setClassification(value)}
                    />
                  </div>
                </div>

                <div className="flex items-center gap-4">
                  <div className="w-full">
                    <label htmlFor="" className="font-medium text-sm">
                      Version<sup className="text-[#1B4DFF]">*</sup>
                    </label>
                    <input
                      className="w-full bg-white border border-[#AFBACA] rounded-md text-sm p-2.5 placeholder:text-[#AFBACA]"
                      type="text"
                      placeholder="Version name"
                      value={version}
                      onChange={(e) => setVersion(e.target.value)}
                    />
                  </div>

                  <div className="w-full">
                    <label htmlFor="" className="font-medium text-sm">
                      Review cycle<sup className="text-[#1B4DFF]">*</sup>
                    </label>
                    <div className="mt-1">
                      <SelectComponent
                        placeholder="eg. Yearly, Quarterly"
                        options={review}
                        value={reviewCycle}
                        onChange={(value) => setReviewCycle(value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-4">
                  <div className="w-full">
                    <label htmlFor="" className="font-medium text-sm">
                      Owner<sup className="text-[#1B4DFF]">*</sup>
                    </label>
                    <div className="mt-1">
                      <SelectComponent
                        placeholder="Select team member"
                        options={personnel.filter((person) => {
                          return (
                            person.personnel.roleName === "REVIEWER" ||
                            person.personnel.roleName === "SUPER_ADMIN"
                          );
                        })}
                        defaultValue={value}
                        // value={owner}
                        onChange={(value) => {
                          setOwner(value.personnel.publicId);
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-full">
                    <label htmlFor="" className="font-medium text-sm">
                      Approval<sup className="text-[#1B4DFF]">*</sup>
                    </label>
                    <div className="mt-1">
                      <SelectComponent
                        placeholder="Select team member"
                        options={personnel.filter((person) => {
                          return (
                            person.personnel.roleName === "APPROVER" ||
                            person.personnel.roleName === "SUPER_ADMIN"
                          );
                        })}
                        // value={approver}
                        defaultValue={approver}
                        onChange={(value) => {
                          setApprover(value.personnel.publicId);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <label htmlFor="" className="font-medium text-sm">
                    Tag to frameworks<sup className="text-[#1B4DFF]">*</sup>
                  </label>
                  <div className="grid grid-cols-2 gap-y-4 py-4 border-b border-[#D7DFE9]">
                    {activeFrameworks?.map((framework) => (
                      <div
                        className="flex items-center gap-4"
                        key={framework.publicId}
                      >
                        <Checkbox
                          value={framework.publicId}
                          onChange={onChange}
                          checked={vegeelFrameworkPublicIds.includes(
                            framework.publicId
                          )}
                        />
                        <div className="flex items-center gap-2">
                          <img
                            src={framework.logo}
                            alt={framework.name}
                            className="h-[24px] w-[24px]"
                          />
                          <p className="text-[#455468] font-medium text-sm">
                            {framework.name}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <button
                  onClick={handleUploadDocument}
                  className="font-medium text-sm text-[#fff] border border-[#D7DFE9] bg-[#0F3CD9] flex gap-1 py-2 px-4 rounded-md w-full justify-center items-center"
                >
                  {uploadDocsLoading ? (
                    <span className="flex gap-2 items-center">
                      Submitting{" "}
                      <Spin
                        indicator={<LoadingOutlined color="white" spin />}
                      />
                    </span>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
