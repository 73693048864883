import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import useFetch from "hooks/useFetch";
import { editCompanyInfoService } from "services/company-setting";
import { IOrgDataDetails, IOrgDataResponse } from "context/home/app";
import { uploadFilesService } from "../../services/app";

interface CompanyInfoContextType {
  updatedOrgInfo: any;
  setUpdatedOrgInfo: React.Dispatch<React.SetStateAction<any>>;
  handleEditCompanySetting: any;
  editCompanyInfoData: any;
  editCompanyInfoError: any;
  editCompanyInfoLoading: any;
}

const CompanyInfoContext = createContext<CompanyInfoContextType | undefined>(
  undefined
);

export const CompanyInfoProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [updatedOrgInfo, setUpdatedOrgInfo] = useState<IOrgDataDetails | null>(
    null
  );

  const {
    data: editCompanyInfoData,
    error: editCompanyInfoError,
    loading: editCompanyInfoLoading,
    fetchData: editCompanyInfoFetchData,
  } = useFetch<IOrgDataResponse>();

  const handleEditCompanySetting = async () => {
    let newOrgInfo = { ...updatedOrgInfo };

    if (updatedOrgInfo) {
      console.log("newOrgInfo", newOrgInfo);
      try {
        if (newOrgInfo.logo instanceof File) {
          console.log("testing two");
          const response = await uploadFilesService(
            newOrgInfo.logo,
            updatedOrgInfo.companyName
          );

          newOrgInfo.logo = response.data.secure_url;
        }
      } catch (error) {}
    }

    editCompanyInfoFetchData(() =>
      editCompanyInfoService(updatedOrgInfo?.businessUrls.publicId, newOrgInfo)
    );
  };

  return (
    <CompanyInfoContext.Provider
      value={{
        updatedOrgInfo,
        setUpdatedOrgInfo,
        handleEditCompanySetting,
        editCompanyInfoData,
        editCompanyInfoLoading,
        editCompanyInfoError,
      }}
    >
      {children}
    </CompanyInfoContext.Provider>
  );
};

export const useCompanyInfoContext = () => {
  const context = useContext(CompanyInfoContext);
  if (context === undefined) {
    throw new Error(
      "useCompanyInfoContext must be used within a CompanyInfoProvider"
    );
  }
  return context;
};
